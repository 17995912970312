/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralStatus,
  SortType,
  SpecialtiesDto,
  SpecialtiesDtoListResponseModel,
  SpecialtiesDtoModelResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Specialties<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Specialties
     * @name PostSpecialties
     * @request POST:/Specialties/Add
     */
    postPost = (data: SpecialtiesDto, params: RequestParams = {}) => this.postSpecialties(data,params)
  /**
   * No description
   *
   * @tags Specialties
   * @name PostSpecialties
   * @request POST:/Specialties/Add
   */
  postSpecialties = (data: SpecialtiesDto, params: RequestParams = {}) =>
    this.request<SpecialtiesDtoModelResponse, any>({
      path: `/Specialties/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Specialties
   * @name LoadList
   * @request GET:/Specialties/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
      ProviderId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<SpecialtiesDtoListResponseModel, any>({
      path: `/Specialties/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Specialties
   * @name GetByIdList
   * @request GET:/Specialties/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<SpecialtiesDtoModelResponse, any>({
      path: `/Specialties/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Specialties
   * @name DeleteDelete
   * @request DELETE:/Specialties/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<SpecialtiesDtoModelResponse, any>({
      path: `/Specialties/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Specialties
   * @name UpdateCreate
   * @request POST:/Specialties/Update
   */
  updateCreate = (data: SpecialtiesDto, params: RequestParams = {}) =>
    this.request<SpecialtiesDtoModelResponse, any>({
      path: `/Specialties/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
